import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Heading, Flex, Paragraph } from 'reakit'

import {
	Container,
	Body,
	TopBar,
	HomeFooter
} from '../components/layouts'

import {
	Button,
	Loader
} from '../components/ui'

import {getUrlVars} from '../util'

import { unsubscribe } from '../factory'

import Layout from '../components/layout'

import theme from '../theme/content'

class Unsubscribe extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			formValidate: null,
		}

		this.submitUnsubscribe = this.submitUnsubscribe.bind(this)
		this.renderButton = this.renderButton.bind(this)
	}

	componentDidMount() {
	}

	submitUnsubscribe() {
		const code = getUrlVars().code
		this.props.unsubscribe({code})
	}

	renderButton() {
		if (this.props.loading) {
			return <Loader />
		}
		return (
			<Button as="button" onClick={()=> this.submitUnsubscribe()}>
				Confirm Unsubscribe
			</Button>
		)
	}

	render() {
		const content = theme.pagesContent.forgot
		const success = 'unsubscribe' in this.props.success ? this.props.success.unsibscribe : false
		return (
			<Layout>
				<TopBar />
				<Container maxHeight="100vh">
					<Body bgColor={'transparent'} bg={content.bg} maxWidth="100%" style={{padding: '20 20 20 20'}}>
						<Flex
							width={'90%'}
							height={'80vh'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Card
								background={'transparent'}
								boxShadow="none"
								width="60%"
								maxWidth="450px"
							>
								<Heading
									as="h1"
									fontSize="35px"
									margin="0 0 0.5em 0"
								>
									Sorry to see you go...
								</Heading>
								<Flex margin="0 0 1rem 0" column>
									{success ?
										<Paragraph>You have been successfully unsubscribed from our email list</Paragraph>
										: this.renderButton()}
									{this.props.error && <Paragraph>{this.props.error}</Paragraph>}
								</Flex>
							</Card>
						</Flex>
					</Body>
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

Unsubscribe.propTypes = {
	loading: PropTypes.bool,
	success: PropTypes.object,
	error: PropTypes.string,
	unsubscribe: PropTypes.func
}

const mapStateToProps = ({userState}) => {
	return {
		success: userState.success,
	 	error: userState.error,
		loading: userState.loading
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		unsubscribe: (data) => dispatch(unsubscribe(data))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Unsubscribe)
